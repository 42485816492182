<script lang="js">
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount
} from '@nuxtjs/composition-api'
import Vue from 'vue'
import LoadWhenVisible from 'components/theme/utils/LoadWhenVisible.vue';
import { useContentRouter } from '@wemade-vsf/composables'
//eslint-disable vue/no-unused-components
export default Vue.extend({
  name: 'CmsContent',
  props: {
    content: {
      type: String,
      default: ''
    },
    styles: {
      type: String,
      default: ''
    }
  },
  computed: {
    dynamicComponent () {
      return defineComponent({
        name: 'CmsRenderedContent',
        template: `<div class="cms-content" ref="content"><component :is="'style'" type="text/css">${this.styles}</component>${this.content}</div>`,
        components: {
          LoadWhenVisible,
          Banner: () => import('components/theme/Content/Magento/Banner.vue'),
          ImageBanner: () => import('components/theme/Content/Magento/ImageBanner.vue'),
          ProductGrid: () => import('components/theme/Content/Magento/ProductGrid.vue'),
          SfAccordion: () => import('~/storefrontUI/components/components/organisms/SfAccordion/SfAccordion.vue'),
          SfTabs: () => import('~/storefrontUI/components/components/organisms/SfTabs/SfTabs.vue'),
          AttachmentDownload: () => import('components/theme/Content/Magento/AttachmentDownload.vue'),
          EmployeeGrid: () => import('components/theme/Content/Magento/EmployeeGrid.vue'),
          FaqCategories: () => import('components/faq/FaqCategories.vue'),
          AttributeCollection: () => import('components/theme/Content/Magento/AttributeCollection.vue'),
          BlogPostList: () => import('components/theme/Content/Magento/BlogPostList.vue'),
          ProductCarousel: () => import('components/theme/Content/Magento/ProductCarousel.vue'),
          ImageGallery: () => import('components/theme/Content/Magento/ImageGallery.vue'),
          ContactForm: () => import('components/contact/ContactForm.vue'),
          AttachmentList: () => import('components/theme/Content/Magento/AttachmentList.vue'),
          WmVideoFacade: () => import('components/theme/Content/Magento/VideoFacade.vue'),
          PbSlider: () => import('components/theme/Content/Magento/PbSlider.vue'),
          PbSlide: () => import('components/theme/Content/Magento/PbSlide.vue'),
          PbAccordion: () => import('components/theme/Content/Magento/PbAccordion.vue'),
          PbAccordionItem: () => import('components/theme/Content/Magento/PbAccordionItem.vue'),
          CurrencySwitcher: () => import('components/theme/CurrencySwitcher.vue'),
        },
        setup () {
          const content = ref(null)
          const { bind, unbind } = useContentRouter()

          onMounted (() => {
            if (content.value) {
              bind(content)
            }
          })

          onBeforeUnmount(() => {
            if (content.value) {
              unbind(content)
            }
          })

          return {
            content
          }
        }
      })
    }
  },
  render (createElement, context) {
    return createElement("div", [
      createElement(this.dynamicComponent)
    ])
  }
})
</script>
<style lang="scss">
@import "styles/theme/cmsContent/cmsContent.scss";
</style>